<template>
<section class="feedback">
    <el-form class="form-content" ref="form" :model="form" :rules="rules" label-width="100px" label-position="left">
        <el-form-item label="您的称呼" prop="name">
            <el-input class="pay-input" v-model="form.name" placeholder="请输入您的称呼"></el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="tel">
            <el-input class="pay-input" v-model="form.tel" type="tel" placeholder="请输入您的联系方式"></el-input>
        </el-form-item>
        <el-form-item label="联系方式">
            <el-input class="pay-input textarea" v-model="form.dec" resize="none" type="textarea"  placeholder="可以简单描述一下您的情况，如公司/品牌名称、需
求等"></el-input>
        </el-form-item>
        <el-form-item>
           
        </el-form-item>
    </el-form>

    <div class="footer-btn">
         <el-button @click="resetHandler">取消</el-button>
        <el-button type="primary" @click="submitHandler">保存</el-button>
    </div>
</section>
</template>

<script>

let init = false;
export default {
    data(){
        return {
            form:{
                name:'',
                tel:'',
                dec:''
            },
            rules:{
                name:[{required:true,message:'请输入您的称呼',trigger:'blur'}],
                tel:[{required:true,message:'请输入您的联系方式',trigger:'blur'}],
            }
        }
    },
    methods:{
        async submitHandler(){
            if(init){
                this.$message.error('请不要重复提交');
                return;
            }
            this.$refs['form'].validate(async valid=>{
                if(!valid) return;
                const rs = await this.$https.feedback({
                    nickname: this.form.name,
                    phone: this.form.tel,
                    describe:this.form.dec
                });
                this.$message.success('提交成功');
                init = true;
                setTimeout(()=>{
                    init = false;
                },5*1000)
            })
        },
        resetHandler(){
            this.form = {
                name:'',
                tel:'',
                dec:''
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.feedback {
    background: #fff;
    padding: 20px;
    height: 100%;
    position: relative;

    .form-content {
        width: 500px;

        ::v-deep textarea {
            height: 200px;
        }
    }
}
</style>